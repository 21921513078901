<template>
  <div class="details_main">
    <d-header :details="cerObj"/>
    <d-banner :details="cerObj"/>
    <d-work :details="cerObj"/>
    <d-work-course :details="cerObj"/>
  </div>
</template>
<script>
const DHeader =()=>import('./components/detils/pageHeader.vue');
const DBanner =()=>import('./components/detils/dBanner.vue');
const DWork =()=>import('./components/detils/work.vue');
const DWorkCourse =()=>import('./components/detils/workCourse.vue');
import {giveLessonsInfo} from '@/apis/certificate.js';
import {mapState} from 'vuex';
export default {
  name:'cer_details'
  ,data(){
    return{
      cerObj:{},
    }
  },
  components:{
    'd-header':DHeader,
    'd-banner':DBanner,
    'd-work':DWork,
    'd-work-course':DWorkCourse,
  },
  computed: {
			...mapState({
				proToken: state => state.indexStore.B_PRO_TOKEN,
			})
		},
  created(){
    // 获取用户信息，如果有加载模块页面， 没有跳转登录页
			if(!this.proToken) {
				this.$router.push({
					path: "/VclassesCenterLogin",
					query: {
						navtype: 'certificate',
					}
				})
			}
    this.getDetails();
  },
  methods:{
    getDetails(){
      let id=this.$route.query.id;
      giveLessonsInfo({id}).then(res=>{
        let msg=res.data;
        if(msg.status==0){
          this.cerObj=msg.result;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.details_main{
  background-color: #F8F9FA;
}
</style>